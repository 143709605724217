<template>
  <el-card :title="form.id ? '修改共享数据' : '添加共享数据'">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="120px"
    >
      <b-row class="mr10">
        <b-col md="6">
          <el-form-item
            label="共享数据来源"
            class="form-item-flex"
            prop="sourceId"
            :rules="[ { required: true, message: '共享数据来源不能为空', trigger: 'change' }]"
          >
            <el-cascader
              v-model="form.assetsType"
              :options="assetsTypeOption"
              style="width: 50%;"
              placeholder="资产类型"
              popper-class="cas-class"
              :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'label' }"
              @change="queryAssetsOptions(1)"
            />
            <el-select
              v-model="form.sourceId"
              class="ml10"
              placeholder="请选择数据来源"
              @change="form.fieldIdList = [];getSourceFiledList()"
            >
              <el-option
                v-for="(item,index) in allAssetsList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="共享数据字段"
            prop="fieldIdList"
            :rules="[ { required: true, message: '共享数据字段不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.fieldIdList"
              multiple
              placeholder="请选择共享数据字段"
            >
              <el-option
                v-for="(item,index) in fieldOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="业务系统"
            prop="productId"
            :rules="[ { required: true, message: '业务系统不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.productId"
              placeholder="请选择业务系统"
            >
              <el-option
                v-for="(item,index) in productOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="共享对象"
            prop="thirdPartyId"
            :rules="[ { required: true, message: '共享对象不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.thirdPartyId"
              placeholder="请选择共享对象"
            >
              <el-option
                v-for="(item,index) in thirdPartyOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>

        <b-col md="12">
          <el-form-item
            label="共享目的及用途"
          >
            <el-input
              v-model="form.shareDescription"
              type="textarea"
              maxlength="300"
            />
          </el-form-item>
        </b-col>

        <b-col md="12">
          <el-form-item
            label="共享的方式"
            prop="shareMethod"
            :rules="[ { required: true, message: '共享的方式不能为空', trigger: 'change' }]"
          >
            <el-checkbox-group v-model="form.shareMethod">
              <el-checkbox
                v-for="(item,index) in shareMethodOptions"
                :key="index"
                :label="item.label"
              />
            </el-checkbox-group>
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="传输方式"
            prop="sendingLine"
            :rules="[ { required: true, message: '传输方式不能为空', trigger: 'change' }]"
          >
            <el-checkbox-group v-model="form.sendingLine">
              <el-checkbox
                v-for="(item,index) in shareTransmissionWayOptions"
                :key="index"
                :label="item.label"
              />
            </el-checkbox-group>
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="传输方式说明"
          >
            <el-input
              v-model="form.sendingLineExplain"
              type="textarea"
              maxlength="300"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="传输保护方式"
            prop="sendingSecureWay"
            :rules="[ { required: true, message: '传输保护不能为空', trigger: 'change' }]"
          >
            <el-radio-group v-model="form.sendingSecureWay">
              <el-radio
                v-for="(item,index) in sendingIsEncryptionOption"
                :key="index"
                :label="item.value"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </b-col>
        <b-col
          v-if="form.sendingSecureWay > 0"
          md="12"
        >
          <el-form-item label="传输保护说明">
            <el-input
              v-model="form.sendingEncryptionExplain"
              type="textarea"
              maxlength="300"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="数据保护方式"
            prop="dataSecureWay"
            :rules="[ { required: true, message: '数据保护方式不能为空', trigger: 'change' }]"
          >
            <el-radio-group v-model="form.dataSecureWay">
              <el-radio
                v-for="(item,index) in dataSecureWayOptions"
                :key="index"
                :label="item.value"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </b-col>
        <b-col
          v-if="form.dataSecureWay > 0"
          md="12"
        >
          <el-form-item label="数据保护方式说明">
            <el-input
              v-model="form.dataSecureWayExplain"
              type="textarea"
              maxlength="300"
            />
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { getAllProduct } from '@/api/enterprise/product'
import { GetThirdPartyOptionList, GetAllAssetsTypeList, QueryAssetsOptionsByType } from '@/api/enterprise/assest'
import { saveOrUpdateShareData, GetUpdateInfo } from '@/api/thirdPartCompliance/thirdPartCompliance'
import { selectFiledBySource } from '@/api/dataMap/dataMapProcess'

export default {
  data() {
    return {
      fieldOptions: [],
      thirdPartyOptions: [],
      shareMethodOptions: [
        { label: 'API', value: 'API' },
        { label: '存储介质电子拷贝', value: '存储介质电子拷贝' },
        { label: 'Email', value: 'Email' },
        { label: 'FAX', value: 'FAX' },
        { label: '远程连接', value: '远程连接' },
        { label: '纸件打印', value: '纸件打印' },
        { label: '其他', value: '其他' },
      ],
      sendingIsEncryptionOption: [
        { label: '没有保护', value: 0 },
        { label: '对称加密传输', value: 1 },
        { label: '非对称加密传输', value: 2 },
        { label: '其他加密方式', value: 3 },
      ],
      dataSecureWayOptions: [
        { label: '没有保护措施', value: 0 },
        { label: '加密', value: 1 },
        { label: '脱敏', value: 2 },
        { label: '加密和脱敏', value: 3 },
        { label: '其他保护措施', value: 4 },
      ],
      // ['内网传输','公网传输','跨地域传输','跨境传输']
      shareTransmissionWayOptions: [
        { label: '内网传输', value: '内网传输' },
        { label: '公网传输', value: '公网传输' },
        { label: '跨地域传输', value: '跨地域传输' },
        { label: '跨境传输', value: '跨境传输' },
      ],
      loading: false,
      assetsTypeParentOptions: [],
      productOptions: [],
      fromTypeOptions: [
        { label: '资产', value: 1 },
        { label: '业务', value: 2 },
        { label: '第三方', value: 3 },
        { label: '业务活动', value: 4 },
      ],
      form: {
        fieldIdList: [],
        shareMethod: [],
        sendingLine: [],
        sendingLineExplain: '',
        productId: '',
        dataSecureWay: '',
        dataSecureWayExplain: '',
        sendingEncryptionExplain: '',
        sendingSecureWay: '',
        assetsType: '',
        thirdPartyId: '',
        sourceType: 1,
        shareDescription: '',
        shareEncryption: 1,
        shareDesensitization: 1,
        id: 0,
        sourceId: '',
      },
      allAssetsList: [],
      assetsTypeOption: [],
    }
  },
  created() {
    if (this.$route.query.id) {
      this.form.id = Number(this.$route.query.id)
      this.getUpdateInfo()
    }
    this.getAllAssetsTypeList()
    this.getAllProduct()
    this.getPrivacyFieldOptionsList()
    this.getThirdPartyOptions()
    sessionStorage.setItem('DataShare', '2')
  },
  mounted() {
    this.navActiveInit(1, '数据共享管理', '第三方合规管理')
  },
  destroyed() {
    this.navActiveInit(2, '数据共享管理', '第三方合规管理', '/dataShare')
  },
  methods: {
    queryAssetsOptions(type) {
      QueryAssetsOptionsByType({ assetsType: this.form.assetsType }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.allAssetsList = resData.data
          if (type === 1) {
            this.form.sourceId = undefined
          }
        }
      })
    },
    getAllAssetsTypeList() {
      GetAllAssetsTypeList().then(res => {
        this.assetsTypeOption = res.data.data
      })
    },
    getUpdateInfo() {
      this.loading = true
      GetUpdateInfo({ id: this.form.id }).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          resData.data.sendingLine = resData.data.sendingLine ? resData.data.sendingLine.split(',') : []
          resData.data.shareMethod = resData.data.shareMethod ? resData.data.shareMethod.split(',') : []
          this.form = resData.data
          this.queryAssetsOptions()
          this.getSourceFiledList()
        }
      })
    },
    getSourceFiledList() {
      selectFiledBySource(0, this.form.sourceId, 1, 0).then(res => {
        if (res.data.code === 0) {
          this.fieldOptions = res.data.data
        }
      })
    },
    getThirdPartyOptions() {
      GetThirdPartyOptionList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.thirdPartyOptions = resData.data
        }
      })
    },
    getPrivacyFieldOptionsList() {
      selectFiledBySource(1, this.form.sourceId, 1).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          if (resData.data) {
            this.fieldOptions = resData.data
          }
        }
      })
    },
    getAllProduct() {
      getAllProduct().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
        }
      })
    },
    back() {
      sessionStorage.setItem('activeName', '2')
      this.$router.push('/dataShare')
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const submitData = { ...this.form }
          submitData.shareMethod = submitData.shareMethod.length > 0 ? submitData.shareMethod.join(',') : ''
          submitData.sendingLine = submitData.sendingLine.length > 0 ? submitData.sendingLine.join(',') : ''
          saveOrUpdateShareData(submitData).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style>
</style>
